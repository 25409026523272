const theme = {
  colors: {
    primary: '#568472',
    dark: '#282827',
    mid: '#959594',
    light: '#FAFAFB',
  },
  utils: {
    xxl: '5.653rem',
    xl: '2.827rem',
    lg: '1.999rem',
    md: '1.414rem',
    sm: '1rem',
    xs: '.707rem',
    xxs: '.25rem',
  },
}

export default theme
