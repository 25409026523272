import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import 'reset-css'
import { ThemeProvider } from 'emotion-theming'
import styled from '@emotion/styled'
import theme from './theme.js'
import Welcome from './welcome.js'
import Video from './video.js'
import Info from './info.js'
import Nav from './nav.js'
import About from './about.js'
import Footer from './footer.js'
import Insta from './insta.js'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/dmg8krf.css"></link>
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyles>
        <Nav />
        <Welcome />
        <Video />
        <Info />
        <main>{children}</main>
        <About />
        <Insta />
        <Footer />
      </GlobalStyles>
    </ThemeProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// Global Styles
const GlobalStyles = styled.div`
  background: ${props => props.theme.colors.light};
  min-height: 100vh;
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: ${props => props.theme.colors.dark};
  line-height: 1.414;
  * {
    box-sizing: border-box;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: 1;
  }
  strong {
    font-weight: 700;
  }
  i,
  em {
    font-style: italic;
  }
`
