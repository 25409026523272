import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import aboutImg from '../images/cc-about.jpg'

const About = () => (
  <AboutWrapper id="about">
    <AboutContent>
      <Fade delay={500}>
        <div>
          <h2>About us:</h2>
          <p>
            Hello! My name is Lydia and I have been a barista for 9 consecutive
            years. I started at a local coffee shop in 2011 and then moved to
            the corporate coffee world in 2014. I started my business in 2018
            and got hired on at a coffee roaster here in Tyler, TX. Working at a
            coffee roaster has allowed me to expand my knowledge of coffee ten
            fold. I have learned all about the business side, the culture,
            harvest schedules, roast profiles, latte art, you name it! My
            husband, Tim, is extremely supportive and frequently accompanies me
            on many of my gigs, but he is a full time plumber and works for a
            company here in the Tyler area. We live on the North side of Tyler
            along with our two dogs, Lucy and Polly.
          </p>
          <p>
            Where did we come up with the name Caffé Corretto? I was looking for
            a good business name one day, while trying to bear in mind that I
            would be mixing coffee and liquor together. I started scrolling
            through traditional coffee drinks and found the "Caffé Corretto" and
            knew it embodied exactly what I had in mind. A "Caffé Corretto" is
            actually the name of an Italian drink. It combines a double shot of
            espresso with a shot of grappa, which is a grape based brandy. When
            I tell people the name of my business I usually will tagline it
            with, "Two f's, two r's, two t's, and an o.".
          </p>
        </div>
      </Fade>
      <Fade right delay={250}>
        <div>
          <img src={aboutImg} alt="About Us: Caffe Corretto Catering" />
        </div>
      </Fade>
    </AboutContent>
  </AboutWrapper>
)

export default About

// Component Styles
const AboutWrapper = styled.section`
  padding: ${props => props.theme.utils.xxl} 0;
    calc(${props => props.theme.utils.xxl});
  @media (max-width: 600px) {
    padding: ${props => props.theme.utils.xxl} 0;
  }
`

const AboutContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${props => props.theme.utils.xl};
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  > * {
    align-self: center;
  }
  img {
    height: auto;
    width: 100%;
    box-shadow: ${props => props.theme.utils.xl}
      ${props => props.theme.utils.xl} 0px -${props => props.theme.utils.xxs} rgba(86, 132, 114, 1);
  }
  h2 {
    font-size: ${props => props.theme.utils.lg};
    margin-bottom: ${props => props.theme.utils.md};
    color: ${props => props.theme.colors.primary};
  }
  p {
    margin-bottom: ${props => props.theme.utils.sm};
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    display: flex;
    flex-flow: column;
    div:first-of-type {
      order: 2;
    }
    div:last-of-type {
      order: 1;
    }
    img {
      max-width: 300px;
      display: block;
      margin-bottom: ${props => props.theme.utils.xl};
      box-shadow: ${props => props.theme.utils.md}
        ${props => props.theme.utils.md} 0px -${props => props.theme.utils.xxs} rgba(86, 132, 114, 1);
    }
  }
`
