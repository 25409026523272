import React from 'react'
import styled from '@emotion/styled'
import {
  FaFacebook,
  FaInstagram,
  FaYelp,
  FaPhone,
  FaEnvelope,
} from 'react-icons/fa'

const Footer = () => (
  <FooterWrapper>
    <FooterContent>
      <ul>
        <li>
          <a
            href="tel:1-903-830-3716"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPhone />
          </a>
        </li>
        <li>
          <a
            href="mailto:info@caffecorretto.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/thecaffecorretto/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/thecaffecorretto"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.yelp.com/biz/caff%C3%A9-corretto-espresso-catering-tyler"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYelp />
          </a>
        </li>
      </ul>
      <h5>Caffé Corretto &copy; {new Date().getFullYear()}</h5>
    </FooterContent>
  </FooterWrapper>
)

export default Footer

// Component Styles
const FooterWrapper = styled.footer`
  background: ${props => props.theme.colors.dark};
  padding: ${props => props.theme.utils.xl} 0;
`

const FooterContent = styled.div`
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  h5 {
    text-align: center;
    color: ${props => props.theme.colors.light};
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${props => props.theme.utils.md};
    li {
      font-size: 1.75rem;
      margin-left: ${props => props.theme.utils.md};
      color: ${props => props.theme.colors.light};
      svg {
        display: block;
      }
      :first-of-type {
        margin-left: 0;
      }
    }
    a {
      color: ${props => props.theme.colors.light};
      :hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`
