import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

const Welcome = () => (
  <WelcomeWrapper>
    <WelcomeContent>
      <Fade delay={250}>
        <h1>Welcome to Caffé Corretto!</h1>
        <p>The East Texas Mobile Coffee and Espresso Bar.</p>
      </Fade>
    </WelcomeContent>
  </WelcomeWrapper>
)

export default Welcome

// Component Styles
const WelcomeWrapper = styled.header`
  background: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.utils.xxl} 0;
  @media (max-width: 600px) {
    padding: ${props => props.theme.utils.xl} 0;
  }
`

const WelcomeContent = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.light};
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  h1 {
    font-size: ${props => props.theme.utils.xl};
    margin-bottom: ${props => props.theme.utils.xs};
  }
  p {
    font-size: ${props => props.theme.utils.md};
  }
`
