import React from 'react'
import Image from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import styled from '@emotion/styled'
import useInsta from '../hooks/useInsta'

const Insta = () => {
  const instaPhotos = useInsta()
  return (
    <InstaWrapper>
      <InstaContent>
        <Fade delay={500}>
          {instaPhotos.map(photo => (
            <a
              href={`https://instagram.com/p/${photo.id}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image key={photo.id} fluid={photo.fluid} alt={photo.caption} />
            </a>
          ))}
        </Fade>
      </InstaContent>
    </InstaWrapper>
  )
}

export default Insta

// Component Styles
const InstaWrapper = styled.section`
  padding: 0 0 calc(${props => props.theme.utils.xxl});
  @media (max-width: 600px) {
    padding: 0 0 ${props => props.theme.utils.sm};
  }
`

const InstaContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${props => props.theme.utils.xl};
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  img {
    height: auto;
    width: 100%;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 0 ${props => props.theme.utils.xxl};
  }
`
