import React from 'react'
import styled from '@emotion/styled'
import logoImg from '../images/caffe-corretto-logo.png'

const Nav = () => (
  <NavWrapper>
    <NavContent>
      <img src={logoImg} alt="Caffe Corretto Logo" />
      <ul>
        <li>
          <a href="#packages">Packages</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#faq">FAQ</a>
        </li>
        <li>
          <a
            href="mailto:info@caffecorretto.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect
          </a>
        </li>
      </ul>
    </NavContent>
  </NavWrapper>
)

export default Nav

// Component Styles
const NavWrapper = styled.nav`
  background: ${props => props.theme.colors.light};
  padding: ${props => props.theme.utils.md} 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
`

const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  img {
    height: auto;
    width: 100%;
    max-width: 250px;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: ${props => props.theme.utils.sm};
      a {
        text-decoration: none;
        color: ${props => props.theme.colors.dark};
        :hover {
          color: ${props => props.theme.colors.primary};
        }
      }
      :last-of-type {
        margin-right: 0;
        background: ${props => props.theme.colors.primary};
        padding: ${props => props.theme.utils.xs}
          ${props => props.theme.utils.lg};
        border-radius: ${props => props.theme.utils.md};
        color: ${props => props.theme.colors.light};
        a {
          color: ${props => props.theme.colors.light};
          :hover {
            color: ${props => props.theme.colors.dark};
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    flex-flow: column;
    img {
      margin-bottom: ${props => props.theme.utils.sm};
    }
  }
`
