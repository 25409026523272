import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

const Video = () => (
  <VideoWrapper>
    <VideoContent>
      <Fade bottom delay={250}>
        <Vimeo>
          <iframe
            title="Caffe Corretto Video"
            src="https://player.vimeo.com/video/273055673?color=fcfcfa&title=0&byline=0&portrait=0"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </Vimeo>
      </Fade>
    </VideoContent>
  </VideoWrapper>
)

export default Video

// Component Styles
const VideoWrapper = styled.section`
  background: linear-gradient(
    180deg,
    ${props => props.theme.colors.primary} 50%,
    ${props => props.theme.colors.light} 50%
  );
`

const VideoContent = styled.div`
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
`

const Vimeo = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
