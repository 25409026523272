import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import infoImg from '../images/cc-info.jpg'

const Info = () => (
  <InfoWrapper>
    <InfoContent>
      <Fade left delay={250}>
        <div>
          <img src={infoImg} alt="Info: Caffe Corretto Catering" />
        </div>
      </Fade>
      <Fade delay={500}>
        <div>
          <h2>A bit about coffee catering:</h2>
          <p>
            What is coffee catering? It's like food catering, except instead of
            serving dinner, we are serving made to order hot and cold coffee
            shop drinks. It's a mobile 6' long espresso bar carrying a double
            group head espresso machine and a commercial grade espresso grinder,
            complete with cups and syrups. It's capable of serving lattes,
            americanos, cappuccinos, caramel macchiatos, hot or iced, hot cocoa,
            tea, chai lattes, and italian sodas.
          </p>
          <p>
            Coffee catering is a unique and much appreciated treat, that people
            will not expect to be spoiled with. Imagine walking into your break
            room, a friend's wedding, or a party, and ordering the same drink
            you would at your favorite coffee shop. It's just that special.
          </p>
        </div>
      </Fade>
    </InfoContent>
  </InfoWrapper>
)

export default Info

// Component Styles
const InfoWrapper = styled.section`
  padding: ${props => props.theme.utils.xxl} 0;
  @media (max-width: 600px) {
    padding: ${props => props.theme.utils.xxl} 0;
  }
`

const InfoContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${props => props.theme.utils.xl};
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  > * {
    align-self: center;
  }
  img {
    height: auto;
    width: 100%;
    box-shadow: -${props => props.theme.utils.xl} ${props =>
        props.theme.utils.xl} 0px -${props => props.theme.utils.xxs}
      rgba(86, 132, 114, 1);
  }
  h2 {
    font-size: ${props => props.theme.utils.lg};
    margin-bottom: ${props => props.theme.utils.md};
    color: ${props => props.theme.colors.primary};
  }
  p {
    margin-bottom: ${props => props.theme.utils.sm};
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    display: flex;
    flex-flow: column;
    img {
      max-width: 300px;
      display: block;
      margin-bottom: ${props => props.theme.utils.xl};
      box-shadow: -${props => props.theme.utils.md} ${props =>
          props.theme.utils.md} 0px -${props => props.theme.utils.xxs}
        rgba(86, 132, 114, 1);
    }
  }
`
